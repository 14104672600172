<template>
  <div>
    <a-page-header style="padding: 0" sub-title="Vouchers">
      <div slot="title">
        <h1>RELATÓRIOS</h1>
      </div>
      <div slot="extra"></div>
    </a-page-header>

    <div class="panel mt-10">
      <div class="modules">
        <div
          v-for="(report, index) in reports"
          :key="index"
          class="module report"
        >
          <div class="card contratos" @click="$router.push(report.route)">
            <div class="ico" style="left: -15px; top: -35px; font-size: 128px">
              <i
                v-if="report.icon.includes('fawsome')"
                :class="report.icon"
              ></i>
              <a-icon
                v-if="!report.icon.includes('fawsome')"
                :type="report.icon"
              />
            </div>
            <div class="name">
              {{ report.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reports: [
        {
          name: "Emissões",
          route: "/vouchers/reports/emissions",
          icon: "fawsome fas fa-ticket-alt",
        },
      ],
    };
  },
  beforeMount() {
    document.title = "Relatórios Vouchers - HAYA";
  },
};
</script>
